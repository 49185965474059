import imageZero from '../images/image2.png';
import imageOne from '../images/image5.png';

import imageTwo from '../images/image1.png';
import imageThree from '../images/image3.png';
import imageFour from '../images/image4.png';

const images = {
    imageZero,
    imageOne,
    imageTwo,
    imageThree,
    imageFour,
}

export default images;
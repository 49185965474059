import * as THREE from 'three';
import { Vector2 } from 'three';
import images from './images';

import fragment from './shaders/fragment.glsl';
import vertex from './shaders/vertex.glsl';
import { gsap } from "gsap";
import { Flip } from "gsap/Flip";
import luge from '@waaark/luge';

gsap.registerPlugin(Flip);



// Moving CTAs to the right, animating everything so it stays responsive and smooth

/////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

const ctaBtn = document.querySelector(".cta-cont");
const meImg = document.querySelector(".me");


function btnAnim(){
    meImg.classList.remove("transition-on");
    
    let scroll = window.scrollY;

    if (scroll > 0) {
        const stat = Flip.getState (".cta-cont, .hero-title-cont, .me", {props: "flexDirection, justifyContent, alignItems"});
        
        ctaBtn.classList.add("cta-scroll");
       
        
        Flip.from(stat, {
            nested:true,
            duration:0.5,
        });
        
    }
    else {
        
        if (scroll == 0) {
        const state = Flip.getState (".cta-btns, .hero-title-cont, .me ", {props: "flexDirection, justifyContent, alignItems"});
        
        // meImg.classList.toggle("transition-off");
        ctaBtn.classList.remove("cta-scroll");
        ctaBtn.classList.add("cta-cont");
        
        
        
        Flip.from(state, {
            nested:true,
            absolute:true,
            duration:0.5,
            // stagger:0.5,
        }
        
        );
        
    };
       
    }; 
    // meImg.classList.toggle("transition-on");
};

window.addEventListener('scroll', btnAnim);

////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
// Picture of me animation (in gsap because css transition breaks Flip plugin)

// const picOfme = document.querySelector(".me");

luge.reveal.add('in', 'trying', (meImg) => {
    // When the element gets in viewport
    gsap.from(meImg, {scaleX:0.7,scaleY:0.7,opacity:0, duration:0.1} )
    gsap.to(meImg, {scaleX:1, scaleY:1, opacity:1} )
  });


//dropdown animation

const faqTitle = document.querySelectorAll(".faqBox");

faqTitle.forEach((faqBox) => {
    faqBox.addEventListener("click", () => {   
            // removeActive();
    
            const state = Flip.getState (".faqBox, .faqTitleCont, .faq-answer, .faq-arrow", {props: "opacity, transform, marginBottom, justifyContent, alignItems"});
            faqBox.classList.toggle("active");

            Flip.from(state, {
            nested:true,
            duration:0.4,
            stagger:0.02,
            })

        });
});

/////////////////////////////////////////////////////////////////////////

//Go to work section
document.querySelector("#work").addEventListener("click", toWork);

function toWork(){
document.querySelector("#workTitle").scrollIntoView({behavior: "smooth"
});
};


/////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////



// THREE.JS STUFF
//Easing animation, takes a start and an end point and calculates a ratio between the two.

function lerp(start, end, t)
{
    return start * ( 1 - t) + end * t;
}

// Mouse coordinates.

let targetX = 0;
let targetY = 0;


//Load image textures for the Mesh


const textureOne = new THREE.TextureLoader().load(images.imageZero);
const textureTwo = new THREE.TextureLoader().load(images.imageOne);
const textureThree = new THREE.TextureLoader().load(images.imageTwo);
const textureFour = new THREE.TextureLoader().load(images.imageThree);
const textureFive = new THREE.TextureLoader().load(images.imageFour);


class Webgl{
    constructor(){
        this.container = document.querySelector('.projects');
        this.links = [...document.querySelectorAll('.projli')];
        this.scene = new THREE.Scene();
        this.perspective = 1000; // Camera Perspective on the z axis
        this.sizes = new THREE.Vector2(0,0); // Mesh sizes
        this.offset = new THREE.Vector2(0,0);
        this.uniforms = {
            uTexture: {value: textureOne},
            uAlpha: {value: 0.0},
            uOffset: {value: new THREE.Vector2(0.0, 0.0)}
        }
    
        this.links.forEach((link, idx) => {
            link.addEventListener('mouseenter', () => {
                switch(idx){
                    case 0:
                        this.uniforms.uTexture.value = textureOne;
                        break;
                    case 1:
                        this.uniforms.uTexture.value = textureTwo;
                        break;
                    case 2:
                        this.uniforms.uTexture.value = textureThree;
                        break;
                    case 3:
                        this.uniforms.uTexture.value = textureFour;
                        break;
                    case 4:
                        this.uniforms.uTexture.value = textureFive;
                        break;
                }
                
                const stateDesc = Flip.getState (".list-desc-cont, .projli", {props: "height, opacity"});
            link.classList.toggle("hovered");

        Flip.from(stateDesc, {
        nested:true,
        absolute: true,
        })
            })

            link.addEventListener('mouseleave', () => {
                this.uniforms.uAlpha.value = lerp(this.uniforms.uAlpha.value, 0.0, 0.1);
                const stateDesc = Flip.getState (".list-desc-cont, .projli", {props: "height, opacity"});
            link.classList.remove("hovered");

        Flip.from(stateDesc, {
        nested:true,
        absolute: true,
        })
            });
        })
        this.addEventListeners(document.querySelector('.projul'));
        this.setupCamera();
        this.onMouseMove();
        this.createMesh();
        this.render();
    }
    
    get viewport(){
        let width = window.innerWidth;
        let height = window.innerHeight;
        let aspectRatio = width/height
        
        return {
            width,
            height,
            aspectRatio,
        }
    }

   

    addEventListeners(element){
        element.addEventListener('mouseenter', () => {
            this.linkHovered = true;
        })
        element.addEventListener('mouseleave', () => {
            this.linkHovered = false;
        })
    }

    setupCamera(){

        //readjust dimensions when resizing the window
        window.addEventListener('resize', this.onWindowResize.bind(this))

        let fov = (180 * (2 * Math.atan(this.viewport.height / 2 / this.perspective))) / Math.PI;
        this.camera = new THREE.PerspectiveCamera(fov, this.viewport.aspectRatio, 0.1, 1000);
        this.camera.position.set(0, 0, this.perspective);

        // Rendered /canvas
        this.renderer = new THREE.WebGL1Renderer({antialias: true, alpha: true});
        this.renderer.setSize(this.viewport.width, this.viewport.height);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.container.appendChild(this.renderer.domElement)
    }


    createMesh(){
        this.geometry = new THREE.PlaneGeometry(1,1,20,20);
       //this.material = new THREE.MeshBasicMaterial({color: 0xff0000});
       this.material = new THREE.ShaderMaterial({
        uniforms: this.uniforms,
        vertexShader: vertex,
        fragmentShader: fragment,
        transparent: true,
    });
        this.mesh = new THREE.Mesh(this.geometry, this.material);
        this.sizes.set(400, 500, 1);

        this.mesh.scale.set(this.sizes.x, this.sizes.y, 1);
        this.mesh.position.set(this.offset.x, this.offset.y, 0);
        this.scene.add(this.mesh);

    }

    onWindowResize(){
        this.camera.aspect = this.viewport.aspectRatio;
        this.camera.fov = (180 * (2 * Math.atan(this.viewport.height / 2 / this.perspective))) / Math.PI;
        this.renderer.setSize(this.viewport.width, this.viewport.height);
        this.camera.updateProjectionMatrix();
    }


    onMouseMove(){
        window.addEventListener('mousemove' , (e) => {
            // targetX = (e.clientY / window.innerHeight) *2 - 1;
            // targetY = (e.clientY / window.innerHeight) *2 + 1;
            targetX = e.clientX ;
            targetY = e.clientY ;
    
        })
    }


    render(){
        this.offset.x = lerp(this.offset.x, targetX, 0.1);
        this.offset.y = lerp(this.offset.y, targetY, 0.1);
        this.uniforms.uOffset.value.set((targetX - this.offset.x) * 0.0005, -(targetY - this.offset.y) * 0.0005 );
        // this.mesh.position.copy(pos);
        this.mesh.position.set(this.offset.x - (window.innerWidth /2), -this.offset.y + (window.innerHeight /2), 0);
        
        
        //change uAlpha when list is hovered or not
        this.linkHovered
        ? this.uniforms.uAlpha.value = lerp(this.uniforms.uAlpha.value, 1.0, 0.1)
        : this.uniforms.uAlpha.value = lerp(this.uniforms.uAlpha.value, 0.0, 0.1)
       
        // for(let i = 0; i < this.links.length; i++){
        //     if(this.linkHovered){
        //         this.links[i].style.opacity = 0.2;
        //     }
        //     else{
        //         this.links[i].style.opacity = 1;
        //     }
        // }

        this.renderer.render(this.scene, this.camera);   
        window.requestAnimationFrame(this.render.bind(this));
    }
}

new Webgl();


/////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

